import { StaticImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'
import ArrowRight from '@assets/icons/arrow_right_sm.svg'
import React from 'react'

const PortalsPromo = () => {
    return (
        <>
            <p class="w-full text-center text-xs mb-4 text-grey font-extrabold uppercase">Rabat dla nowych klientów</p>
            <h3 class="w-full text-center text-[32px] leading-[32px] mb-6">nieruchomosci-online.pl</h3>
            <div className="lg:w-[100%]">
                <StaticImage
                    src="../../assets/images/portals/nieruchomosci-online.png"
                    alt="Logo nieruchomości online"
                    className="lg:!block max-w-[300px] mx-auto mb-6"
                />
                <ul className="imo-bullet-list">
                    <li>Dwa miesiące bezpłatnego eksportu w ramach dowolnego pakietu abonamentowego</li>
                    <li>
                        Pakiet promocyjny zawierający 5 wyróżnień 30-dniowych, do wykorzystania w trakcie ww. okresu
                    </li>
                </ul>
                <p className="text-secondary mt-6">
                    Aby skorzystać z oferty, prosimy skontaktować się z portalem po kliknięciu w link:{' '}
                    <a target="_blank" href="https://n-o.pl/start-z-imo" className="text-blue hover:underline">
                        https://n-o.pl/start-z-imo
                    </a>
                </p>
            </div>
        </>
    )
}

export default PortalsPromo
