export const source = [
    { label: 'Polecenie', value: 'Polecenie' },
    { label: 'Kontakt handlowca', value: 'Kontakt handlowca' },
    { label: 'Google/Wyszukiwarki', value: 'Google/Wyszukiwarki' },
    { label: 'Facebook/Messenger', value: 'Facebook/Messenger' },
    { label: 'Informacja na portalu', value: 'Informacja na portalu' },
    { label: 'Mailing', value: 'Mailing' },
    { label: 'Inne', value: 'Inne' },
]

export const currentProgram = [
    { label: 'Nie używam programu', value: 'Nie używam programu' },
    { label: 'Agencja 3000', value: 'Agencja 3000' },
    { label: 'Asari web', value: 'Asari web' },
    { label: 'Asari Kameleon', value: 'Asari Kameleon' },
    { label: 'BiuroWin Net', value: 'BiuroWin Net' },
    { label: 'BON24', value: 'BON24' },
    { label: 'Broker Office', value: 'Broker Office' },
    { label: 'DEGA Software', value: 'DEGA Software' },
    { label: 'e-Biuro Nieruchomości', value: 'e-Biuro Nieruchomości' },
    { label: 'eKOMAKO', value: 'eKOMAKO' },
    { label: 'EstiCRM', value: 'EstiCRM' },
    { label: 'Galactica Virgo', value: 'Galactica Virgo' },
    { label: 'iHouse', value: 'iHouse' },
    { label: 'IMO', value: 'IMO' },
    { label: 'inet online/info2Net', value: 'inet online/info2Net' },
    { label: 'LocumNet Online', value: 'LocumNet Online' },
    { label: 'LocumNet Desktop', value: 'LocumNet Desktop' },
    { label: 'mediaRent', value: 'mediaRent' },
    { label: 'MLS 2007', value: 'MLS 2007' },
    { label: 'MLS MILENIUM', value: 'MLS MILENIUM' },
    { label: 'Multiple', value: 'Multiple' },
    { label: 'next-est', value: 'next-est' },
    { label: 'Partner X (Asari)', value: 'Partner X (Asari)' },
    { label: 'Property 2.0', value: 'Property 2.0' },
    { label: 'REPS', value: 'REPS' },
    { label: 'UniaNet', value: 'UniaNet' },
    { label: 'Vixcrm', value: 'Vixcrm' },
    { label: 'Domuly', value: 'Domuly' },
]
export const websiteType = [
    {
        label: 'Z nowym projektem graficznym',
        value: 'Z nowym projektem graficznym',
    },
    { label: 'Ekspresową', value: 'Ekspresową' },
]

export const numberWithSeparation = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
}

export const promotionValidDate = () => {
    const date = new Date()
    const startDate = new Date('2024-10-01')
    const endDate = new Date('2024-12-01')

    return date.getTime() >= startDate.getTime() && date.getTime() <= endDate.getTime()
}
